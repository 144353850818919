(function () {
  'use strict';

  class LearningIndexCtrl {
    constructor(Resources, Modules, $mdToast, $scope, $state, $filter, $document, $timeout, CloneResourceFlow, $rootScope, $mdDialog) {
      let vm = this;
      vm.ctrlName = 'IndexCtrl';
      vm.Resources = Resources;
      vm.Modules = Modules;
      vm.$filter = $filter;
      vm.$document = $document;
      vm.$timeout = $timeout;
      vm.$mdToast = $mdToast;
      vm.$state = $state;
      vm.$mdDialog = $mdDialog;
      vm.loadingResource = true;
      vm.collapsedResource = false;
      vm.resourcesQuery = {
        per_page: 20,
        order_by: 'title',
        order: 'asc'
      };
      vm.CloneResourceFlow = CloneResourceFlow;
      vm.$scope = $scope;
      vm.$rootScope = $rootScope;
      vm.$scope.$on('resource:update', function () {
        vm.init();
      });
      vm.init();
    }
    sortBy() {
      const vm = this;
      if (vm.resourcesQuery.order === 'asc') {
        vm.resourcesQuery.order = 'desc';
      } else {
        vm.resourcesQuery.order = 'asc';
      }
      vm.getResources();
    }
    init() {
      const vm = this;
      vm.getResources();
      // vm.getModules();
    }
    getResources() {
      const vm = this;
      vm.loadingResource = true;
      vm.Resources.resourcesIndex(vm.resourcesQuery)
        .then((data)=>{
          vm.resourcesView = data;
          vm.resourcesQuery.page = vm.resourcesView.meta.page;
        })
        .finally(() => {
          vm.loadingResource = false;
        });
    }
    getModules() {
      const vm = this;
      vm.loadingModule = true;
      vm.Modules.modulesIndex(vm.modulesQuery)
        .then((data)=>{
          vm.moduleView = data;
        })
        .finally(() => {
          vm.loadingModule = false;
        });
    }
    resourceFilterToggleHandler(dashboard) {
      const vm = this;
      dashboard.resourceFilterToggle = !dashboard.resourceFilterToggle;
      vm.$timeout(() => {
        vm.$document[0].querySelector('[name="resourceQuery"]').focus();
      }, 100);
    }
    menu($mdMenu, ev) {
      $mdMenu.open(ev);
    }
    cloneResource(e, node) {
      let vm = this;
      vm.CloneResourceFlow.show(e, node, (clone)=> {
        clone.id = node.id;
        vm.Resources.resourceClone(clone)
          .then(()=> {
            vm.getResources();
          });
      });
    }
    deleteResource(node, ev) {
      let vm = this,
          confirm = vm.$mdDialog.confirm()
            .title('Confirm')
            .textContent('Are you sure you want to delete this?')
            .ariaLabel('Confirm')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      vm.$mdDialog.show(confirm).then(function () {
        vm.Resources.resourceDelete({id: node.id})
          .then(()=> {
            vm.getResources();
          });
      });
    }
    beforeResource() {
      const vm = this;
      vm.resourcesQuery.page--;
      vm.getResources();
    }
    nextResource() {
      const vm = this;
      vm.resourcesQuery.page++;
      vm.getResources();
    }
  }

  /**
   * @ngdoc object
   * @name learning.index.controller:IndexCtrl
   *
   * @description
   *
   */
  angular
    .module('learning.index')
    .controller('LearningIndexCtrl', LearningIndexCtrl);
}());
